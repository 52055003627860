import JPAllCategoryDark from "~/assets/img_jp_all_category_dark.webp";
import JPAllCategoryLight from "~/assets/img_jp_all_category_light.webp";
import usePrefersColorScheme from "~/hooks/usePrefersColorScheme";

import Image from "../services/Image";
import * as css from "./CircleThumbnailCard.css";

type CircleThumbnailCardProps = {
  image: string;
  title: string;
  theme?: "homePage" | "categoriesPage";
};

export default function CircleThumbnailCard({
  image,
  title,
  theme,
}: CircleThumbnailCardProps) {
  return (
    <article className={css.cardContainer({ theme: theme ?? "base" })}>
      <div className={css.imageContainer}>
        <Image
          wrapperClassName={css.imageWrapper}
          imgClassName={css.image}
          src={image}
          alt="thumbnail"
        />
      </div>
      <div>
        <div className={css.title}>{title}</div>
      </div>
    </article>
  );
}

const JP_ALL_CATEGORY_IMAGE = {
  light: JPAllCategoryLight,
  dark: JPAllCategoryDark,
};
export function JPAllCategoryCircleThumbnailCard({
  theme,
}: Pick<CircleThumbnailCardProps, "theme">) {
  const prefersColorScheme = usePrefersColorScheme();
  return (
    prefersColorScheme && (
      <CircleThumbnailCard
        image={JP_ALL_CATEGORY_IMAGE[prefersColorScheme]}
        title="すべてのカテゴリ"
        theme={theme}
      />
    )
  );
}
